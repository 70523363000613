import React from 'react'
import {QRCodeSVG} from 'qrcode.react';
import {  useTranslation } from 'react-i18next';

const InvoiceHeader = ({
    logo,
    companyName,
    companyCR,
    companyDesc,
    vatNo,
    inv_no,
    inv_date,
    inv_date_due,
    companyAddress,
    qrText,
    invoiceSettings
}) => {

    const [t] = useTranslation('global')
    console.log('invoiceSettings',invoiceSettings);
    return (
        <div>
            <div className='flex items-center justify-between py-10'>
                <div className={`flex `}>
                    {invoiceSettings?.SHOW_LOGO_ON_PRINT === 'true' && <div><img src={logo} className='w-24' /></div> }
                    <div className='ps-2 '>
                        <div className='font-bold py-2 text-sm' >{companyName}</div>
                        <div className='flex flex-col gap-1 text-xs text-gray-500'>
                            <div>{companyDesc}</div>
                            <div className='flex items-center justify-between gap-3'>
                                <span>   سجل تجاري:</span>
                                <span className='font-bold'>{companyCR}</span>
                                <span>:.C.R </span>
                            </div>
                            <div className='flex items-center justify-between gap-3'>
                                <span>  الرقم الضريبي:</span>
                                <span className='font-bold'>{vatNo}</span>
                                <span>:VAT NO</span>
                            </div>
                            <div >{companyAddress}</div>
                        </div>
                    </div>
                </div>
                {/* <div className='flex'>
                    <QRCodeSVG size={120}  value={qrText}  />
                    </div> */}
            </div>
                    <div className='flex-1 pb-7 flex items-center justify-center'>
                        <div className='text-sm font-bold items-center  flex flex-col '>
                            <span> فاتورة شراء </span>
                            <span>Purchese Invoice</span>
                        </div>
                    </div>
            <div  className='flex justify-between text-xs rounded-t-lg border  pt-1 '>
                <div className=' flex-1 flex items-center justify-between gap-3 p-2 '> 
                    <div className='flex flex-col text-center'>
                        <span>تاريخ الاصدار</span>
                        <span>ISSUE DATE</span>
                    </div>
                    <span className='font-bold'>{inv_date.substring(0,10)}</span>
                </div>
                <div className='border-l border-r flex-1 flex items-center justify-between gap-3 p-2  '> 
                <div className='flex flex-col text-center'>
                    <span> رقم الفاتورة</span>
                    <span>INVOICE NO</span>
                </div>
                    <span className='font-bold'>{inv_no}</span>
                </div>
                <div className=' flex-1 flex items-center justify-between gap-3 p-2 '> 
                    <div className='flex flex-col text-center'>
                        <span>تاريخ الاستحقاق</span>
                        <span>DUE DATE</span>
                    </div>
                    <span className='font-bold'>{inv_date_due}</span>
                </div>
            </div>
            {/* <hr className='my-2'/> */}
        </div>
    )
}

export default InvoiceHeader