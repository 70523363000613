import React from 'react'
import SalesReportsListItem from './SalesReportsListItem'
import { useInvoice } from '../../hooks'

const SalesReportsList = ({data}) => {
    const {formatter} = useInvoice()
    const sum_total_amount = data?.data?.reduce((partialSum, a) => parseFloat(a.total_amount || 0) + parseFloat(partialSum ||0), 0);
    const sum_discount_amount = data?.data?.reduce((partialSum, a) => parseFloat(a.discount_amount || 0) + parseFloat( partialSum || 0), 0);
    const sum_discount_amount_after = sum_total_amount - sum_discount_amount
    const sum_total_amount_vat = data?.data?.reduce((partialSum, a) => parseFloat(a.vat_amount || 0  ) + parseFloat(partialSum || 0), 0);
    const sum_net_amount = data?.data?.reduce((partialSum, a) => parseFloat(a.net_amount || 0) + parseFloat(partialSum || 0), 0);
    
    return (
        <div className=' my-5 rounded-lg bg-slate-100'>
            <div className='flex gap-3 justify-between items-center bg-slate-200 p-3 rounded-t-lg text-slate-600  text-sm '>
                <div className='w-20 text-center'>رقم الفاتورة</div>
                {/* <div className='w-24 text-center'>الرقم المرجعي</div> */}
                <div className=' text-center'>تاريخ الفاتورة</div>
                {/* <div className='w-24 text-center'>تاريخ الاستحقاق</div> */}
                <div className='w-20 text-center'>مبلغ الفاتورة</div>
                <div className='w-16 text-center'>الخصم</div>
                <div className='w-24 text-center text-xs'>الإجمالي بعد الخصم</div>
                <div className='w-16 text-center'>الضريبة</div>
                <div className='w-20 text-center'> الإجمالي</div>
                <div className='w-20 text-center'>حالة الدفع</div>
            </div>
            {
                data.data.map(item => (
                    <SalesReportsListItem key={item.id} item={item} formatter={formatter}/>
                ))
            }
            <div className='flex gap-3 justify-between items-center bg-slate-200 p-3 rounded-b-lg text-slate-600  text-sm '>
                <div className='w-20 text-center'> </div>
                <div className=' text-center'></div>
                <div className='w-20 text-center font-bold'> {formatter.format(Math.round(sum_total_amount,2))}</div>
                <div className='w-16 text-center font-bold '>{formatter.format(Math.round(sum_discount_amount,2))}</div>
                <div className='w-24 text-center font-bold'> {formatter.format(Math.round(sum_discount_amount_after,2))} </div>
                <div className='w-16 text-center font-bold'>{formatter.format(Math.round(sum_total_amount_vat,2))}</div>
                <div className='w-20 text-center font-bold'> {formatter.format(Math.round(sum_net_amount,2))}</div>
                <div className='w-20 text-center'> </div>
            </div>
        </div>
    )
}

export default SalesReportsList