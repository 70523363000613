import {useState,useEffect} from 'react'
// import { GrView } from 'react-icons/gr';
// import { FiEdit } from 'react-icons/fi';
// import { RiDeleteBinLine } from 'react-icons/ri';
import SearchVendor from '../SearchVendor';
import ListVendorsItem from './ListVendorsItem';
import NoDataFound from '../ui/NoDataFound';
import { ConfirmDelete, MwButton, MwSpinner } from '../ui';
import {MdOutlineAddBox} from 'react-icons/md'
import CardHeadeWithActions from '../ui/CardHeadeWithActions';
import { useCurrent, useDelete, useFetch } from '../../hooks';

const ListVendors = ({setMode,idItemSelected,setIdItemSelected}) => {
    const [showConfirmDelete,setShowConfirmDelete] = useState(false);
    const { currentTenantId ,currentCompanyId,currentBranchId} = useCurrent();
    const [vendorsList,setVendorsList] = useState(); 
    const {data:deleteResult,deleteItem} = useDelete()
    const {data:dataCustomers,loading,refreshHandler} = useFetch(`${process.env.REACT_APP_VENDORS_API_BASE_URL}/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const onChangeSearch = (newValue) => {
        //setData(newValue);
        if(newValue.length === 0){
            refreshHandler()
        }
        setVendorsList(newValue);
    }

    const editHandeler = (id) =>{
        setIdItemSelected(id)
        setMode('Edit')
    }
    const deleteHandeler = (id) =>{
        setIdItemSelected(id)
        setShowConfirmDelete(true);
    }

    const confirmDelete = () =>{
        deleteItem(`${process.env.REACT_APP_VENDORS_API_BASE_URL}/delete/${idItemSelected}`)
    }
    
    useEffect(() =>{
        if(dataCustomers){
            setVendorsList(dataCustomers.data)
        }
    },[dataCustomers])

    useEffect(()=>{
        if(deleteResult){
            console.log('deleteResult',deleteResult)
            refreshHandler()
            setShowConfirmDelete(false);
        }
    },[deleteResult]);

    return (
        <>
            <ConfirmDelete onDeleteHandler={confirmDelete} onShow={showConfirmDelete} setOnShow={setShowConfirmDelete} msg={`هل انت متاكد من حذف العميل`} />
            <div className=''>
                <CardHeadeWithActions title={`قائمة الموردين`}>
                    <SearchVendor className={`bg-white`} isOpen={false} onChangeSearch={onChangeSearch} />
                    <MwButton inGroup={true} type='saveBtn' onClick={()=>setMode('Add')}  >
                        <MdOutlineAddBox size={18}/>
                        أضف مورد جديد
                    </MwButton>
                </CardHeadeWithActions>
                <div className='my-3 '>
                    {loading && <MwSpinner/>}
                    {
                        !loading && vendorsList && vendorsList?.length > 0 ? 
                            <div className='pt-3'>{
                                vendorsList.map((item) =>(<ListVendorsItem key={item._id} item={item} deleteHandeler = {deleteHandeler} editHandeler = {editHandeler} /> ))
                                }</div> :
                            <NoDataFound msg={`لا يوجد موردين `}/>
                    }
                </div>
            </div>
        </>
    )
}

export default ListVendors